<template>
  <div class="w-100-p p-0">
    <div class="pos-r">
      <div class="background-image" />
      <div class="bg-overlay" />
    </div>

    <div
      class="registration-container bg-blue-transparent"
      v-if="!registrationCompleted"
    >
      <div class="row mb-5 d-flex justify-content-center">
        <div
          class="col-xl-4 col-md-6 col-12 d-flex align-items-center flex-column"
        >
          <img
            class="w-25-p"
            src="https://carogusto-live.batix.ch/pic/18B89CD8B48.png"
            alt=""
          />
          <!-- <span class="text-dark-blue"
            >Welcome to the Carogusto Partnerportal!</span
          > -->
          <span class="text-dark-blue mt-3 text-center"
            >Please review your information and make any necessary
            adjustments.</span
          >
        </div>
      </div>
      <div
        class="row px-3 pb-3 d-flex justify-content-center align-items-center flex-column"
      >
        <!-- EMPLOYEE start -->
        <div class="col-xl-6 col-8" v-if="!isManufacturer">
          <div class="row mb-2">
            <div class="col-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="First name"
                  v-model="user.firstname"
                  :class="{'not-validated': !user.firstname}"
                />
                <label>First name*</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Last name"
                  v-model="user.lastname"
                  :class="{'not-validated': !user.lastname}"
                />
                <label>Last name*</label>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Phone number"
                  v-model="user.phone"
                />
                <label>Phone number</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Email address"
                  v-model="user.email"
                  :disabled="true"
                  :class="{'not-validated': !user.email}"
                />
                <label>Email Address*</label>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Role"
                  :disabled="true"
                  v-model="user.role.name"
                  :class="{'not-validated': !user.role.name}"
                />
                <label>Role*</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Position"
                  v-model="user.position"
                />
                <label>Position</label>
              </div>
            </div>
          </div>
        </div>
        <!-- EMPLOYEE end -->

        <!-- COMPANY start -->
        <div class="col-xl-6 col-8" v-if="isManufacturer">
          <div class="row mb-2">
            <div class="col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Company name"
                  v-model="user.company.name"
                  :disabled="true"
                  :class="{'not-validated': !user.company.name}"
                />
                <label>Company name*</label>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Phone number"
                  v-model="user.company.phone"
                />
                <label>Phone number</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Email address"
                  v-model="user.company.email"
                  :disabled="true"
                  :class="{'not-validated': !user.company.email}"
                />
                <label>Email Address*</label>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Address"
                  v-model="user.company.address"
                  :class="{'not-validated': !user.company.address}"
                />
                <label>Address*</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Website"
                  v-model="user.company.website"
                />
                <label>Website</label>
              </div>
            </div>
          </div>
        </div>
        <!-- COMPANY end -->
        <div class="col-xl-6 col-8 mt-2 registrationPasswordInput">
          <div
            class="row mb-2 d-flex justify-content-center align-items-center pos-r"
          >
            <div class="col-6">
              <div class="input-group">
                <span
                  class="login-icon flex-center bg-white text text-dark-blue"
                >
                  <font-awesome-icon icon="fa-regular fa-key" />
                </span>
                <input
                  :type="showpassword ? 'text' : 'password'"
                  name="password"
                  placeholder="Password"
                  aria-describedby="password"
                  autocapitalize="off"
                  spellcheck="false"
                  autocorrect="off"
                  v-model="password"
                />
                <span
                  class="show-password-icon text-dark-blue"
                  @click="toggleShowPassword"
                >
                  <font-awesome-icon
                    class="fa-fw"
                    v-if="!showpassword"
                    icon="fa-light fa-eye"
                  />
                  <font-awesome-icon
                    class="fa-fw"
                    v-if="showpassword"
                    icon="fa-light fa-eye-slash"
                  />
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="input-group">
                <span
                  class="login-icon flex-center bg-white text text-dark-blue"
                >
                  <font-awesome-icon icon="fa-regular fa-key" />
                </span>
                <input
                  :type="showpassword ? 'text' : 'password'"
                  name="password"
                  placeholder="Repeat password"
                  aria-describedby="Repeat password"
                  autocapitalize="off"
                  spellcheck="false"
                  autocorrect="off"
                  v-model="repeatPassword"
                />
                <span
                  class="show-password-icon text-dark-blue"
                  @click="toggleShowPassword"
                >
                  <font-awesome-icon
                    class="fa-fw"
                    v-if="!showpassword"
                    icon="fa-light fa-eye"
                  />
                  <font-awesome-icon
                    class="fa-fw"
                    v-if="showpassword"
                    icon="fa-light fa-eye-slash"
                  />
                </span>
              </div>
            </div>
            <div class="col-8 text-center fs-12 text-danger mb-4 pos-a bottom-44-p">
              <span v-show="!passwordFormatIsValid"
                >The password must have at least 8 characters, upper and lower
                case letters, a number and a special character.</span
              >
            </div>
            <div class="col-8 text-center fs-12 text-danger pos-a bottom-30-p">
              <span v-show="!passwordsMatch">Passwords do not match.</span>
            </div>

 
        <div class="col-8 text-center fs-12 text-danger mt-2 pos-a bottom-44-p">
          <span class="text-dark-blue text-center">
            Fields marked with * are required
          </span>
        </div>
      
            <div class="col-8 mt-100-px">
              <button
                class="btn app-btn w-100"
                @click="registrieren"
                :disabled="!formIsValid"
                :class="{ 'is-disabled': !formIsValid }"
              >
                Register
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="registration-container bg-blue-transparent h-450-px"
      v-if="registrationCompleted"
    >
      <div class="row mb-5 d-flex justify-content-center">
        <div
          class="col-xl-4 col-md-6 col-12 d-flex align-items-center flex-column"
        >
          <img
            class="w-25-p"
            src="https://carogusto-live.batix.ch/pic/18B89CD8B48.png"
            alt=""
          />
          <!-- <span class="text-dark-blue"
            >Welcome to the Carogusto Partnerportal!</span
          > -->
          <span class="text-dark-blue mt-3 text-center"
            >Registration successful!</span
          >
        </div>
      </div>
      <div class="row mb-5 d-flex justify-content-center mt-90-px">
        <div class="cta cta-bold fs-14" @click="goToLogin">Go to Login</div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicApi from "@/PublicApi";
export default {
  name: "Registration",
  components: {},
  mixins: [],

  data() {
    return {
      maxHeight: 0,
      isManufacturer: false,
      showpassword: false,
      password: null,
      repeatPassword: null,
      userID: null,
      token: null,
      registrationCompleted: false,
      user: {
        id: null,
        firstname: null,
        lastname: null,
        phone: null,
        email: null,
        position: null,
        role: { name: null, description: null },
        company: {
          address: "",
          email: null,
          id: null,
          name: null,
          phone: "",
          status: "Active",
          website: "",
        },
      },
    };
  },
  computed: {
    passwordFormatIsValid() {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s])[A-Za-z\d\S]{8,}$/;

      if (this.password) return passwordRegex.test(this.password);
      return true;
    },
    passwordsMatch() {
      if (
        this.password &&
        this.repeatPassword &&
        this.password === this.repeatPassword
      )
        return true;
      if (
        (!this.password && !this.repeatPassword) ||
        (this.password && !this.repeatPassword) ||
        (!this.password && this.repeatPassword)
      )
        return true;
      return false;
    },
    formIsValid() {
      if (this.isManufacturer) {
        if (
          this.passwordsMatch &&
          this.passwordFormatIsValid &&
          this.user.company.name &&
          //this.user.company.phone &&
          this.user.company.email &&
          this.password &&
          this.repeatPassword
        )
          return true;
      } else {
        if (
          this.passwordsMatch &&
          this.passwordFormatIsValid &&
          this.user.firstname &&
          this.user.lastname &&
          //this.user.phone &&
          //this.user.position &&
          this.user.email &&
          this.user.role &&
          this.password &&
          this.repeatPassword
        ) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {},

  created() {
    this.$emit("setNavbarVisibility", false);
    this.getUser();
  },
  mounted() {},
  methods: {
    getUser() {
      this.userID = this.$route.query.id;
      this.token = this.$route.query.token;

      PublicApi.get("/user", {
        params: { token: this.token, id: this.userID },
      }).then((response) => {
        this.user = response.data;
        this.setIsManufacturer(response.data?.role?.id);
      });
    },
    toggleShowPassword() {
      this.showpassword = !this.showpassword;
    },
    registrieren() {
      const path = this.isManufacturer ? "/manufacturer/" : "/employee/";
      let json = this.user;
      let password = encodeURI(this.password);

      PublicApi.post(path, json, {
        params: { token: this.token, password: password },
      }).then(() => {
        this.registrationCompleted = true;
      });
    },
    setIsManufacturer(roleID) {
      if (roleID === "188AEA750CF") this.isManufacturer = true;
    },
    goToLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="scss">
.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(https://carogusto-live.batix.ch/pic/18B89CEB87A.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -2;
}

.bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: -1;
}
.is-disabled {
  cursor: not-allowed;
}
.not-validated {
  border: 1px solid red;
}
</style>
